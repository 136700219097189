import React, { useState } from 'react';

function SectionAI() {
  const [userMessage, setUserMessage] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    if (!userMessage.trim()) return;
    setLoading(true);
    setResponse('');
    try {
      const res = await fetch('https://my-portfolio-server-j99i.onrender.com/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: userMessage }),
      });

      if (!res.ok) {
        throw new Error('Errore nella comunicazione con il server');
      }

      const data = await res.json();
      setResponse(data.response);
    } catch (error) {
      setResponse('Errore: impossibile ottenere una risposta. Riprova più tardi.');
    } finally {
      setLoading(false);
      setUserMessage(''); // Svuota l'input
    }
  };

  return (
    <div className="section-ai-container py-5">
      <div className="container">
        <div className="row">
          {/* Descrizione */}
          <div className="col-12 mb-4">
            <div className="description-box p-4">
              <img src="/openai-logo.png" alt="OpenAI Logo" className="openai-logo mb-3" />
              <h2 className="mb-3">Intelligenza Artificiale</h2>
              <p className="mb-4">
              L'Intelligenza Artificiale (IA) è destinata a rivoluzionare il nostro modo di vivere e lavorare.
Dalle applicazioni quotidiane come assistenti virtuali e automazione, fino a soluzioni avanzate
per la medicina, la robotica e la sostenibilità, l'IA rappresenta uno dei pilastri tecnologici del futuro.
La mia passione per l'Intelligenza Artificiale nasce dal desiderio di esplorare le sue infinite possibilità
e contribuire a creare un mondo più innovativo ed efficiente.
              </p>
              <h3 className="mb-3">ChatGPT</h3>
              <p>
              In basso puoi provare una chat basata su ChatGPT 3.5 Turbo, un modello avanzato sviluppato da OpenAI.
Questo sistema è basato sulle API di OpenAI e può rispondere a domande, fornire suggerimenti e aiutarti
a esplorare le potenzialità dell'IA in modo interattivo. Scrivi un messaggio per iniziare e scopri la
potenza di questa tecnologia!
              </p>
            </div>
          </div>

          {/* Chat */}
          <div className="col-12">
            <div className="chat-container mb-3">
              <div className="message-container">
                {response && (
                  <div className="message ai-message">
                    <p>{response}</p>
                  </div>
                )}
                {loading && (
                  <div className="message ai-message">
                    <p>Sto pensando...</p>
                  </div>
                )}
              </div>
            </div>

            <div className="chat-input-area d-flex">
              <textarea
                className="chat-input form-control me-2"
                rows="2"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder="Digita il tuo messaggio..."
                disabled={loading}
                aria-label="Digita il tuo messaggio"
              />
              <button
                className="send-button btn btn-success"
                onClick={handleSend}
                disabled={loading}
                aria-label="Invia messaggio"
              >
                {loading ? '...' : 'Invia'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionAI;





