import React, { useEffect, useState } from 'react';

function SectionBlockchain() {
  const [blocks, setBlocks] = useState([]);

  const fetchLatestLedger = async () => {
    try {
      const response = await fetch("https://xrplcluster.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          method: "ledger",
          params: [
            {
              ledger_index: "validated",
              accounts: false,
            },
          ],
        }),
      });

      if (!response.ok) {
        console.error("Errore nella richiesta API:", response.statusText);
        return;
      }

      const data = await response.json();
      console.log("Dati API XRPLCluster:", data);

      if (data.result && data.result.ledger) {
        const ledger = data.result.ledger;

        const newBlock = {
          ledgerIndex: ledger.ledger_index,
          hash: ledger.ledger_hash,
          closed: ledger.close_time_human,
          time: new Date(ledger.close_time * 1000).toLocaleTimeString(),
        };

        setBlocks((prevBlocks) => {
          const isDuplicate = prevBlocks.some(
            (block) => block.ledgerIndex === newBlock.ledgerIndex
          );
          if (isDuplicate) {
            return prevBlocks;
          }
          return [newBlock, ...prevBlocks.slice(0, 9)];
        });
      }
    } catch (error) {
      console.error("Errore durante il fetch:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchLatestLedger, 5000);
    fetchLatestLedger();
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="xrp-live-container">
      {/* Intestazione con logo e descrizione */}
      <div className="xrp-header text-center mb-4">
        <img src="/xrplogo.jpg" alt="XRP Logo" className="xrp-logo" />
        <h2 className="mb-3">Perché sono appassionato della blockchain XRP</h2>
        <p className="lead">
  La blockchain rappresenta una rivoluzione tecnologica che offre una soluzione senza precedenti a uno dei problemi più complessi del mondo moderno: la sicurezza dei dati. Mi appassiona perché permette di garantire integrità, trasparenza e affidabilità in settori chiave della nostra società. Questa tecnologia crea un registro distribuito e immutabile, dove ogni transazione o dato è crittografato, verificabile e non alterabile, riducendo drasticamente i rischi di frode o manomissione.

  Uno degli aspetti più affascinanti è il suo utilizzo per garantire la tracciabilità e l'autenticità di prodotti e servizi. Ad esempio, in Italia, la blockchain viene utilizzata per tracciare ogni fase della produzione del vino: dalla coltivazione delle uve alla distribuzione finale. Questo non solo tutela la qualità del prodotto, ma protegge anche i consumatori da contraffazioni e garantisce il rispetto delle tradizioni locali.

  Un altro caso emblematico è rappresentato dalla Ducati, che sfrutta la blockchain per tracciare l'intera filiera di produzione dei propri componenti. Ogni pezzo, dalla fabbricazione alla consegna, è registrato in modo sicuro su una rete distribuita. Questo garantisce che i ricambi siano autentici, protegge la reputazione del marchio e assicura che ogni veicolo sia costruito secondo i più alti standard di qualità. 

  <span style={{ display: "block", marginTop: "10px" }}>
    <a
      href="https://www.ducati.com/it/it/news/ducati-annuncia-l-ingresso-nel-mondo-web-3-0-in-partnership-con-nft-pro"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#61dafb", textDecoration: "underline" }}
    >
      Scopri di più
    </a>
  </span>
</p>
        <h2 className="mb-3">Perché è importante la blockchain per la sicurezza?</h2>
        <p className="lead">
        Nel mondo sempre più connesso dei dispositivi IoT (Internet of Things), la blockchain gioca un ruolo cruciale per garantire la sicurezza dei dati. Con miliardi di dispositivi interconnessi che raccolgono e scambiano informazioni sensibili, il rischio di attacchi hacker e violazioni è elevato. La blockchain fornisce un registro sicuro e decentralizzato in cui i dispositivi possono comunicare e operare senza dipendere da server centralizzati, eliminando così punti di vulnerabilità critici. Ad esempio, in una smart home, la blockchain può garantire che solo dispositivi autorizzati possano accedere alla rete, proteggendo le famiglie da intrusioni esterne.

Oltre alla sicurezza, la blockchain rende le supply chain più trasparenti e resilienti. Pensiamo alle catene di approvvigionamento globali: ogni passaggio, dalla materia prima al prodotto finito, può essere monitorato e verificato. Questo è particolarmente importante per settori sensibili come quello farmaceutico, dove la sicurezza dei medicinali dipende dalla tracciabilità e dall'affidabilità della loro origine.

In sintesi, la blockchain è molto più di una tecnologia emergente: è uno strumento che sta ridefinendo i concetti di sicurezza, fiducia e trasparenza. Mi appassiona perché rappresenta il futuro, in grado di risolvere problemi reali e creare nuove opportunità in un mondo sempre più connesso e digitalizzato.Di sotto potete vedere i blocchi confermati della blockchain di xrp in tempo reale .
        </p>
      </div>

      {/* Contenitore blocchi */}
      <div className="xrp-blocks-container">
        {blocks.map((block) => (
          <a
            key={block.ledgerIndex}
            href={`https://livenet.xrpl.org/ledgers/${block.ledgerIndex}`}
            target="_blank"
            rel="noopener noreferrer"
            className="xrp-block-item-link"
          >
            <div className="xrp-block-item">
              <h5 className="block-ledger">Ledger: {block.ledgerIndex}</h5>
              <p className="block-info">Hash: {block.hash}</p>
              <p className="block-info">Closed: {block.closed}</p>
              <p className="block-info">Time: {block.time}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default SectionBlockchain;
