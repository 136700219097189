import React from "react";

const SectionPassioni = () => {
  return (
    <section className="passioni-section">
      <div className="passioni-container">
     
      
        <div className="passioni-text">
          <h2>Le mie passioni</h2>
          <p>
            Sono sempre stato affascinato dalle nuove tecnologie e dalle loro
            applicazioni nel mondo reale. Una delle mie prime passioni è stata
            il mondo dei videogiochi, che mi ha avvicinato all'universo informatico ed elettronico.
          </p>
          <p>
            Nelle prossime sezioni troverai alcuni ambiti che mi appassionano ed il loro perchè:
            <strong> AI, Blockchain e Robotica.</strong>
          </p>
          <p>
            Ognuna di queste aree rappresenta un tassello fondamentale
            dell'innovazione che mi ha spinto ad approfondire le mie conoscenze e
            competenze.Mi reputo molto fortunato a vivere in questo periodo storico fatto da cosi tanta innovazione e scoperta e spero un domani di poter contribure e fare la mia parte .
          </p>
          
         
        </div>
        <img src="ps1.jpg" alt="Passione videogiochi" class="passioni-image" />
       
      </div>
      
    </section>
  );
};

export default SectionPassioni;
