import React from 'react';

function SectionContatti() {
  return (
    <div className="section-contatti-container py-5">
      <div className="container">
        <h2 className="section-title mb-4 text-center">Contatti</h2>
        <div className="row align-items-center">
          <div className="col-md-6 mb-4">
            <div className="contact-card p-4">
              <h4 className="contact-name mb-3">Ceatlos Alexandru Constantin</h4>
              <p className="contact-info">Nato il 18/05/1997</p>
              <p className="contact-info">
                <strong>Email:</strong> <a href="mailto:alexceatlos@gmail.com" className="contact-link">alexceatlos@gmail.com</a>
              </p>
              <p className="contact-info">
                <strong>Telefono:</strong> <a href="tel:+393665905460" className="contact-link">+393665905460</a>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="location-card p-4">
              <h5 className="location-title mb-3">Dove mi trovo</h5>
              <p className="location-info">Codogno (LO), CAP 26845, Italia</p>
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111371.39529719609!2d9.554045797339684!3d45.15267191079086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781151c2ca97bb9%3A0x9d39151fe14dd75c!2s26845%20Codogno%20LO!5e0!3m2!1sit!2sit!4v1689253288901!5m2!1sit!2sit"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionContatti;
