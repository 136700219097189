import React from 'react';

function SectionProgetti() {
  return (
    <div className="projects-section">
  <div className="intro-text">
    <h2>I miei Progetti</h2>
    <p>
    Questo progetto è stato realizzato per il mio esame di maturità e nasce dall'esperienza diretta durante il mio lavoro presso IKEA. Lì ho notato un problema ricorrente: i bancali venivano spesso posizionati in locazioni sbagliate, causando inefficienze.

Per risolvere questa sfida, ho progettato e stampato in 3D uno scaffale intelligente. Successivamente, ho integrato lettori RFID per tracciare la posizione di ogni bancale e una scheda saldata con LED rossi e verdi per indicare visivamente la correttezza della posizione. Il sistema è stato programmato utilizzando l'IDE di Arduino e disponeva di un menu che permetteva di effettuare controlli sequenziali e garantire l'ordine corretto.
    </p>
  </div>
  <div className="project-details">
    <img src="scaffale.jpg" alt="Progetto scaffale RFID" className="project-image" />
    <div className="project-description">
      <h3>Scaffale RFID Intelligente</h3>
      <p>
      Questo progetto combina tecnologia avanzata, creatività e praticità per ottimizzare i processi logistici e rappresenta una soluzione versatile e adattabile a diverse esigenze.
      </p>
    </div>
  </div>
</div>


  );
}

export default SectionProgetti;