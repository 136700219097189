import React, { useEffect, useState, useRef } from 'react';

function NavbarDots() {
  const [colors, setColors] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(false);

  const animationTimeoutsRef = useRef([]);
  const hideTimeoutRef = useRef(null);

  const sections = [
    'Presentazione',
    'Le mie passioni',
    'I miei progetti',
    'AI',
    'Blockchain',
    'Robot autonomi',
    'Contatti'
  ];

  useEffect(() => {
    // Legge i colori dinamicamente
    const sectionElements = document.querySelectorAll('.content');
    const extractedColors = Array.from(sectionElements).map(sec =>
      getComputedStyle(sec).backgroundColor
    );
    setColors(extractedColors);

    const handleMouseMove = (event) => {
      const navbar = document.querySelector('.navbar');
      if (!navbar) return;
      const navbarRect = navbar.getBoundingClientRect();

      const nearNavbar = (
        event.clientX < navbarRect.right + 50 &&
        event.clientY > navbarRect.top - 50 &&
        event.clientY < navbarRect.bottom + 50
      );

      if (nearNavbar) {
        showDots();
      } else {
        if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
        hideTimeoutRef.current = setTimeout(() => {
          hideDots();
        }, 2000);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    const handleScroll = () => {
      let closestSection = 0;
      let closestDistance = Infinity;
      const sectionElements = document.querySelectorAll('.content');
      sectionElements.forEach((section, index) => {
        const distance = Math.abs(section.getBoundingClientRect().top);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestSection = index;
        }
      });
      setActiveIndex(closestSection);
      // Mostra i pallini quando si cambia sezione, così l'utente è orientato
      showDots();
    };

    const container = document.querySelector('.main-scroll-container');
    container?.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const clearAnimationTimeouts = () => {
    animationTimeoutsRef.current.forEach(t => clearTimeout(t));
    animationTimeoutsRef.current = [];
  };

  const showDots = () => {
    setNavbarVisible(true);
    // Cancella eventuali timeouts
    clearTimeout(hideTimeoutRef.current);
    // Dopo un certo tempo, nascondi di nuovo
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = setTimeout(() => {
      hideDots();
    }, 2000);
  };

  const hideDots = () => {
    setNavbarVisible(false);
  };

  const handleDotClick = (index) => {
    clearAnimationTimeouts();
    const sectionsElements = document.querySelectorAll('.content');
    sectionsElements[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    // Mostra i pallini dopo il click, così l'utente sa dove si trova
    showDots();
  };

  return (
    <div className={`navbar ${navbarVisible ? '' : 'hidden'}`}>
      {sections.map((sec, idx) => (
        <div 
          className={`dot ${idx === activeIndex ? 'active' : ''}`}
          key={idx}
          style={{ backgroundColor: colors[idx] || 'gray' }}
          onClick={() => handleDotClick(idx)}
        >
          <span className="dot-label">{sec}</span>
        </div>
      ))}
    </div>
  );
}

export default NavbarDots;
