import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

function SectionRobots() {
  const mountRef = useRef(null);
  const [terminalOutput, setTerminalOutput] = useState([]);

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x808080); // Sfondo grigio

    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.offsetWidth, mountRef.current.offsetHeight);

    if (mountRef.current) {
      mountRef.current.innerHTML = ''; // Pulisce il contenitore
      mountRef.current.appendChild(renderer.domElement);
    }

    const handleResize = () => {
      const { offsetWidth, offsetHeight } = mountRef.current;
      camera.aspect = offsetWidth / offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(offsetWidth, offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(10, 10, 10);
    scene.add(pointLight);

    function createJoint() {
      const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
      const sphereMaterial = new THREE.MeshStandardMaterial({ color: 0x555555 });
      return new THREE.Mesh(sphereGeometry, sphereMaterial);
    }

    function createArm(length, thickness, color) {
      const geometry = new THREE.BoxGeometry(thickness, length, thickness);
      const material = new THREE.MeshStandardMaterial({ color });
      const arm = new THREE.Mesh(geometry, material);
      arm.position.y = length / 2;
      return arm;
    }

    const baseGroup = new THREE.Group();
    scene.add(baseGroup);

    const base = createArm(1, 4, 0x555555);
    base.position.set(0, 0.5, 0);
    baseGroup.add(base);

    const joint1 = createJoint();
    joint1.position.set(0, 1, 0);
    baseGroup.add(joint1);

    const arm1 = createArm(4, 1, 0xff0000);
    const arm1Group = new THREE.Group();
    arm1Group.add(arm1);
    joint1.add(arm1Group);

    const joint2 = createJoint();
    joint2.position.set(0, 4, 0);
    arm1Group.add(joint2);

    const arm2 = createArm(4, 1, 0x0000ff);
    const arm2Group = new THREE.Group();
    arm2Group.add(arm2);
    joint2.add(arm2Group);

    const joint3 = createJoint();
    joint3.position.set(0, 4, 0);
    arm2Group.add(joint3);

    const arm3 = createArm(4, 1, 0x00ff00);
    const arm3Group = new THREE.Group();
    arm3Group.add(arm3);
    joint3.add(arm3Group);

    const controls = new OrbitControls(camera, renderer.domElement);
    camera.position.set(0, 10, 15);
    controls.update();

    const joints = [
      { joint: joint1, axis: 'Y', angle: 0 },
      { joint: joint2, axis: 'X', angle: 0 },
      { joint: joint3, axis: 'X', angle: 0 },
    ];

    function clampRotation(angle, min = -Math.PI / 2, max = Math.PI / 2) {
      return Math.max(min, Math.min(max, angle));
    }

    let targets = joints.map(() => 0);

    function generateNewTargets() {
      targets = joints.map(() =>
        Math.random() * (Math.PI * 2) - Math.PI
      );
      setTerminalOutput(
        joints.map(
          (j, i) => `Joint ${i + 1}: ${targets[i].toFixed(2)} rad`
        )
      );
    }

    function animate() {
      joints.forEach((j, i) => {
        j.angle += (targets[i] - j.angle) * 0.02;
        j.joint.rotation[j.axis.toLowerCase()] = clampRotation(j.angle);
      });
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }

    generateNewTargets();
    const interval = setInterval(generateNewTargets, 4000);
    animate();

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
      if (mountRef.current) {
        mountRef.current.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="container-fluid py-5 bg-light">
      <h1 className="text-center mb-4">Robotica</h1>
      <div className="d-flex flex-column align-items-center">
        <div className="terminal">
          <h5 className="text-primary">Terminale</h5>
          <div className="terminal-output">
            {terminalOutput.map((line, index) => (
              <p key={index} className="command-line">{line}</p>
            ))}
          </div>
        </div>
        <div className="robot-container" ref={mountRef}></div>
        <p className="text-center mt-2">Puoi interagire con la visualizzazione 3D!</p>
      </div>
      <div className="row mt-4">
        <div className="col-md-6 offset-md-3 robot-description">
          <h3>Perché la robotica è importante?</h3>
          <p>
          La robotica rappresenta una delle frontiere più affascinanti e promettenti della tecnologia moderna. È molto più di un insieme di macchine e algoritmi: è la perfetta fusione tra creatività umana e precisione artificiale. Nel mio percorso, sto scoprendo come la robotica possa diventare il cuore pulsante di un futuro sempre più connesso, dove innovazione e intuizione lavorano insieme per trasformare le nostre vite.

Immaginate un mondo in cui l'intelligenza artificiale permette ai robot di apprendere e adattarsi in tempo reale, offrendo soluzioni a problemi complessi che fino a pochi anni fa sembravano irrisolvibili. Oppure un contesto dove la blockchain, con la sua trasparenza e sicurezza, consente ai sistemi robotici di collaborare su larga scala in ambienti distribuiti, scambiandosi dati e risorse in modo affidabile e autonomo. Queste tecnologie, combinate, non stanno solo cambiando la robotica, ma stanno ridefinendo ciò che significa "possibile".

Pur non avendo ancora alle spalle un bagaglio completo di esperienze, mi sento pienamente immerso in questa avventura. Ogni giorno, la mia curiosità e la voglia di imparare mi spingono a esplorare come queste tecnologie possano trasformare non solo settori industriali, ma anche la vita quotidiana delle persone. È una sfida che affronto con entusiasmo e determinazione, consapevole che la vera innovazione nasce dall'audacia di immaginare l'impossibile.

Voglio contribuire a un futuro in cui i robot non siano solo strumenti, ma collaboratori intelligenti e affidabili, capaci di lavorare in simbiosi con l'uomo. Credo che l'unione tra AI, blockchain e robotica sia una delle chiavi per costruire un domani più equo, sicuro ed efficiente. Non vedo l'ora di essere parte di questa trasformazione, lasciandomi guidare dalla mia passione e dalla voglia di superare i limiti dell'ordinario.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SectionRobots;
