import React from 'react';

function SectionPresentazione() {
  return (
    <div className="container py-5">
      <div className="row align-items-center">
        {/* Colonna testo */}
        <div className="col-md-6 mb-4 mb-md-0">
          <h1 className="display-4">Ciao, sono Ceatlos Alexandru Constantin</h1>
          <p className="lead">
            Nato il 18/05/1997, appassionato di informatica e tecnologia. Questo sito è il mio portfolio interattivo, dove potrai scoprire le mie competenze, le mie passioni e i miei progetti.
          </p>
          <p>
         " Mi appassiona la tecnologia perché credo che possa rendere la vita delle persone più semplice e leggera, aiutandole a concentrarsi su ciò che conta davvero   "        </p>
        </div>
        {/* Colonna immagine */}
        <div className="col-md-6 text-center">
          <img src="/fotoprofilo.jpg" alt="Profilo" className="img-fluid rounded-circle" style={{maxWidth: '300px'}}/>
        </div>
      </div>
    </div>
  );
}

export default SectionPresentazione;
