import React from 'react';
import SectionPresentazione from './components/SectionPresentazione';
import SectionPassioni from './components/SectionPassioni';
import SectionProgetti from './components/SectionProgetti';
import SectionAI from './components/SectionAI';
import SectionBlockchain from './components/SectionBlockchain';
import SectionRobots from './components/SectionRobots';
import SectionContatti from './components/SectionContatti';
import NavbarDots from './components/NavbarDots';
import './App.css';

function App() {
  return (
    <div className="main-scroll-container">
       <NavbarDots />
      <div className="content section-intro"><SectionPresentazione /></div>
      <div className="content section-passions"><SectionPassioni /></div>
      <div className="content section-project"><SectionProgetti /></div>
      <div className="content section-ai"><SectionAI /></div>
      <div className="content section-blockchain"><SectionBlockchain /></div>
      <div className="content section-robots"><SectionRobots /></div>
      <div className="content section-contact"><SectionContatti /></div>
      <footer className="site-footer">
         <p>&copy; 2024 Ceatlos Alexandru Constantin. Tutti i diritti riservati.</p>
      </footer>

    </div>
    
  );
  
}


export default App;

